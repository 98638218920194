export default {
  props: {
    validator: {
      type: Object,
      required: false,
      default: null
    },

    validatorKey: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      validableErrors: []
    };
  },

  methods: {
    $validableClear() {
      this.validableErrors = [];

      if (this.validator && this.validatorKey) {
        this.validator.clearErrors(this.validatorKey);
      }
    },

    $validableTouch() {
      this.$validableClear();

      if (!this.$options.validate || typeof this.$options.validate != 'function') {
        return;
      }

      try {
        this.$options.validate.bind(this)();
      } catch (validationErrors) {
        this.validableErrors = Array.isArray(validationErrors) ? validationErrors : [validationErrors];
        this.$emit('invalid', this.validableErrors);

        if (this.validator && this.validatorKey) {
          this.validator.setErrors(this.validatorKey, this.validableErrors);
        }
      }
    }
  },

  mounted() {
    if (this.validator) {
      this.validator.registerValidable(this);
    }
  }
};