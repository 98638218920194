<template>
  <ui-field
    class="ui-input"
    :label="type != 'checkbox' && type != 'button' ? label : ''"
    :required="required"
    :message="message"
    :disabled="disabled"
  >
    <slot name="default">
      <template v-if="type == 'textarea'">
        <textarea
          style="width: 99%"
          class="ui-native"
          v-model="innerValue"
          @input="doInput"
          v-bind="$attrs"
        ></textarea>
      </template>
      <template v-else-if="type == 'select'">

        <template v-if="$attrs.native">
          <ui-select
            v-model="innerValue"
            @input="doInput"
            v-bind="$attrs"
          ></ui-select>
        </template>
        <template v-else>
          <ui-select-checklist
            v-if="$attrs.multiple"
            v-model="innerValue"
            @input="doInput"
            v-bind="$attrs"
          ></ui-select-checklist>
          <ui-input-radio
            v-else
            v-model="innerValue"
            @input="doInput"
            v-bind="$attrs"
          ></ui-input-radio>
        </template>

        <!-- built in HIDDEN field if "name" attribute is present -->
        <input v-if="$attrs.name" :name="$attrs.name" type="hidden" v-model="innerValue" />

      </template>
      <template v-else-if="type == 'date'">
        <ui-input-date
          v-model="innerValue"
          @input="doInput"
          v-bind="$attrs"
        ></ui-input-date>
      </template>
      <template v-else-if="type == 'timestamp'">
        <ui-input-date
          format="timestamp"
          v-model="innerValue"
          @input="doInput"
          v-bind="$attrs"
        ></ui-input-date>
      </template>
      <template v-else-if="type == 'file'">
        <ui-input-file
          v-model="innerValue"
          @input="doInput"
          v-bind="$attrs"
        ></ui-input-file>
      </template>
      <template v-else-if="type == 'checkbox'">
        <ui-input-checkbox
          v-model="innerValue"
          @input="doInput"
          :label="label"
          v-bind="$attrs"
        ></ui-input-checkbox>

        <!-- built in HIDDEN field if "name" attribute is present -->
        <input v-if="$attrs.name" :name="$attrs.name" type="hidden" v-model="innerValue" />
      </template>
      <template v-else-if="type == 'html'">
        <ui-input-html
          v-model="innerValue"
          @input="doInput"
          :label="label"
          v-bind="$attrs"
        ></ui-input-html>
      </template>
      <template v-else-if="type == 'color'">
        <UiColorPicker
          type="native"
          v-model="innerValue"
          @input="doInput"
          :label="label"
          v-bind="$attrs"
        />
      </template>
      <template v-else-if="type == 'button'">
        <button
          type="button"
          @click="$emit('click')"
          v-bind="$attrs"
          :class="$attrs.theme"
        >{{label || '....'}}</button>
      </template>
      <template v-else>
        <input
          class="ui-native"
          :type="type"
          v-bind="$attrs"
          v-on="inputListeners"
          :value="innerValue"
        />
      </template>
    </slot>
  </ui-field>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useValidable from '@/modules/ui/mixins/useValidable.js';

import {
  UiField,
  UiSelect,
  UiInputCheckbox,
  UiInputDate,
  UiInputFile,
} from '@/modules/ui/components';

import UiSelectChecklist from '@/modules/ui/components/UiSelectChecklist/UiSelectChecklist.vue';
import UiInputRadio from '@/modules/ui/components/UiInputRadio/UiInputRadio.vue';
import UiInputHtml from '@/modules/ui/components/UiInputHtml/UiInputHtml.vue';
import UiColorPicker from '@/modules/ui/components/UiColorPicker/UiColorPicker.vue';

export default {
  name: 'ui-input',
  mixins: [useValidable, useI18n],

  components: {
    UiField,
    UiSelect,
    UiInputCheckbox,
    UiInputDate,
    UiInputFile,
    UiSelectChecklist,
    UiInputRadio,
    UiInputHtml,
    UiColorPicker,
  },

  // Funcion invocada por el mixin useValidable
  validate() {
    if (this.required && (!this.innerValue || !this.innerValue.trim())) {
      throw this.$t('ui.input.errorRequired');
    }
  },

  i18n: {
    en: {
      'ui.input.errorRequired': 'This field is required',
    },
    es: {
      'ui.input.errorRequired': 'Este campo es obligatorio',
    },
  },

  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (value) =>
        [
          'text',
          'number',
          'range',
          'textarea',
          'select',
          'checkbox',
          'date',
          'timestamp',
          'csv',
          'file',
          'password',
          'button',
          'html',
          'color',
        ].includes(value),
    },

    value: {
      required: false,
      default: null,
    },

    // Field props
    label: {
      type: String,
      required: false,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    message: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.innerValue =
          value instanceof Date
            ? new Date(value)
            : JSON.parse(JSON.stringify(value)); //clone
      },
    },
  },

  computed: {
    inputListeners() {
      return {
        ...this.$listeners,

        input: ($event) => {
          this.innerValue = $event.target.value;
          this.doInput();
        },
      };
    },
  },

  methods: {
    doInput() {
      let inputValue =
        this.innerValue instanceof Date
          ? new Date(this.innerValue)
          : JSON.parse(JSON.stringify(this.innerValue));
      this.$emit('input', inputValue);
    },
  },
};
</script>