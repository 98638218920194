<template>
  <div class="ui-color-picker">
    <input
      v-if="type === 'native'"
      type="color"
      v-model="nativeValue"
    />
    <color-picker
      v-else
      :value="value || '#cccccc'"
      @input="$emit('input', $event.hex8)"
    ></color-picker>
  </div>
</template>

<script>
import { Slider } from 'vue-color';

export default {
  name: 'ui-color-picker',

  components: {
    ColorPicker: Slider,
  },

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },

    type: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    nativeValue: {
      get() {
        // convertir cadena rgb(R,G,B) a #xxyyzz
        if (this.value.substr(0, 4) === 'rgb(') {
          let [r, g, b] = this.value
            .substring(4, this.value.length - 1)
            .split(', ')
            .map((v) => parseInt(v));

          return (
            '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
          );
        }

        return this.value || '#cccccc';
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>