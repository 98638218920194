<template>
  <div class="UiHtmlEditorToolbar ui-row">
    <!-- <div class="block-format-dropdown">
      <div
        class="format-name ui-toolbar-item"
        @mousedown.prevent="dropDownIsShown = !dropDownIsShown"
      >
        <span v-if="selection.block == 'h1'">Título</span>
        <span v-else-if="selection.block == 'h2'">Subtítulo</span>
        <span v-else>Párrafo</span>
      </div>

      <div
        class="available-format-list"
        v-show="dropDownIsShown"
      >
        <p
          :class="{'--active': selection.block == 'p'}"
          @mousedown.prevent="setBlockFormat('<P>')"
        >Párrafo</p>
        <h2
          :class="{'--active': selection.block == 'h2'}"
          @mousedown.prevent="setBlockFormat('<H2>')"
        >Subtítulo</h2>
        <h1
          :class="{'--active': selection.block == 'h1'}"
          @mousedown.prevent="setBlockFormat('<H1>')"
        >Título</h1>
      </div>
    </div> -->

    <div class="ui-row --tight command-list">
      <!-- mousedown.prevent previene que la seleccion pierda el foco y el comando se aplique correctamente -->
      <UiIcon
        class="toolbar-button ui-clickable"
        type="button"
        v-for="command in availableCommands"
        :title="command.title"
        :key="command.cmd"
        :value="command.icon"
        :class="{'--active': selection[command.cmd]}"
        @mousedown.native.prevent="cmd(command.cmd)"
      />

      <UiPopover>
        <template #trigger>
          <UiIcon
            class="toolbar-button ui-clickable"
            type="button"
            title="Color"
            value="mdi:palette"
          />
        </template>
        <template #contents>
          <div class="ui-row">
            <UiInput
              style="padding: 4px"
              label="Texto"
              type="color"
              v-model="computedForeColor"
            />
            <UiInput
              style="padding: 4px"
              label="Fondo"
              type="color"
              v-model="computedBackColor"
            />
          </div>
        </template>
      </UiPopover>
    </div>
  </div>
</template>

<script>
import { UiIcon, UiPopover, UiInput } from '@/modules/ui/components';

export default {
  name: 'UiHtmlEditorToolbar',
  components: { UiIcon, UiPopover, UiInput },

  data() {
    return {
      // Información sobre la seleccion de texto actual
      selection: {
        bold: false,
        italic: false,
        underline: false,
        block: null,
        foreColor: null,
        backColor: null,
      },

      dropDownIsShown: false,

      availableCommands: [
        { cmd: 'bold', icon: 'g:format_bold' },
        { cmd: 'italic', icon: 'g:format_italic' },
        { cmd: 'underline', icon: 'g:format_underline' },
        {
          cmd: 'removeFormat',
          icon: 'g:format_clear',
          title: 'Limpiar formato',
        },
      ],
    };
  },

  computed: {
    computedForeColor: {
      get() {
        return this.selection.foreColor;
      },
      set(newValue) {
        document.execCommand('styleWithCSS', false, true);
        document.execCommand('foreColor', false, newValue);
      },
    },

    computedBackColor: {
      get() {
        return this.selection.backColor;
      },
      set(newValue) {
        document.execCommand('styleWithCSS', false, true);
        document.execCommand('backColor', false, newValue);
      },
    },
  },

  methods: {
    cmd(command, value) {
      return document.execCommand(command, false, value);
    },

    onSelectionChange() {
      this.selection.bold = document.queryCommandState('bold');
      this.selection.italic = document.queryCommandState('italic');
      this.selection.underline = document.queryCommandState('underline');
      this.selection.block = document.queryCommandValue('formatblock');
      this.selection.foreColor = document.queryCommandValue('foreColor');
      this.selection.backColor = document.queryCommandValue('backColor');
    },

    setBlockFormat(tag) {
      this.cmd('formatblock', tag);
      this.dropDownIsShown = false;
    },
  },

  mounted() {
    this.onSelectionChange(); // run initial check
    document.addEventListener('selectionchange', this.onSelectionChange);
  },

  beforeDestroy() {
    document.removeEventListener('selectionchange', this.onSelectionChange);
  },
};
</script>

<style lang="scss">
.UiHtmlEditorToolbar {
  position: relative;

  .command-list {
    margin: 0;
  }

  .toolbar-button {
    width: 36px;
    height: 36px;
    color: #777;
    --ui-icon-size: 18px;
  }
}
</style>