<template>
  <div class="ui-input-radio">
    <ui-item
      v-for="(option,index) in options"
      :key="index"
      icon-color="var(--ui-color-primary)"
      :icon="value == option.value ? 'mdi:radiobox-marked' : 'mdi:radiobox-blank'"
      :text="option.text"
      class="ui-clickable ui-noselect"
      @click="$emit('input', option.value)"
    ></ui-item>
  </div>
</template>
<script>
import { UiItem } from '@/modules/ui/components';

export default {
  name: 'ui-input-radio',
  components: {
    UiItem,
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
};
</script>